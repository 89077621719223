import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import VolunteerContent from '../components/About/VolunteerContent';

export const VolunteerPageTemplate = ({
  hero,
  heading,
  subheading,
  blurb,
  cta,
  instagram,
  embed
}) => {
  return (
    <div className="w-full flex flex-col bg-s4tk-white">
      <VolunteerContent
        hero={hero}
        heading={heading}
        subheading={subheading}
        blurb={blurb}
        cta={cta}
        instagram={instagram}
      />
    </div>
  );
};

const VolunteerPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <VolunteerPageTemplate
        hero={frontmatter.hero}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        blurb={frontmatter.blurb}
        cta={frontmatter.cta}
        instagram={frontmatter.instagram}
      />
    </Layout>
  );
};

export default VolunteerPage;

export const volunteerPageQuery = graphql`
  query VolunteerPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "volunteer-page" } }) {
      frontmatter {
        hero {
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          text
        }
        blurb
      }
    }
  }
`;
