import React from 'react';
import Hero from '../Hero';
import Section from '../Section';

export const VolunteerContent = ({ hero, blurb }) => {
  return (
    <>
      <Hero images={hero.images} title={hero.text} />
      <Section>
        <p className="font-baloo mb-4 font-semibold text-s4tk-blue text-2xl sm:text-3xl text-left px-0 md:px-4 lg:px-8">
          {blurb}
        </p>
      </Section>
    </>
  );
};

export default VolunteerContent;
